function readGetVar(key, default_value) {
    if (typeof default_value === 'undefined') {
        default_value = null
    }

    var params = {}

    window.location.search.substring(1, window.location.search.length).split("&").forEach(function(p) {
        var c = p.split('=')

        params[c[0]] = c[1]
    })

    if (params[key]) {
        return params[key]
    }

    return default_value
}

jQuery(document).ready(function() {
    jQuery('#datepicker').change(function() {
        var date = jQuery(this).val()
        var id = readGetVar('_id', '')

        window.location.href = '/manual?_id=' + id + '&date=' + date
    })
})
