(function(ng) {
    'use strict';

    var app = ng.module('login', []);

    app.controller('LoginController', ['$scope', '$http', '$window', function($scope, $http, $window) {
        $scope.username = 'christoph@raketenbasis.com';
        $scope.password = '95pek3tG';

        $scope.error = '';

        $scope.login = function() {
            $scope.error = '';
            
            $http.post('auth', { username: $scope.username, password: $scope.password }).then(function(response) {
                if (response.data.error) {
                    $scope.error = response.data.error;
                } else {
                    $window.location.href = '/';
                }

                console.log(response.data);
            });
        }
    }]);
})(angular);