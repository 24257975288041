// Globale Chart Optionen:
// - Zeitraum (Jahr, Monat, Kalenderwoche, Tag)
// Anlagen Optionen:
// - Zeitraum (Welches Jahr, Welcher Monat, etc.)
// - Angezeigte Felder

(function(ng) {
    var app = ng.module('app', ['nvd3']);

    app.run(function() {
        moment().locale('de');
    });

    app.controller('StatisticsController', ['$scope', '$http', function($scope, $http) {
        $scope.range = function(min, max) {
            var range = [];

            for (var i = min; i <= max; i++)
            {
                range.push(i);
            }

            return range;
        }

        $scope.types = [
            { name: 'Jahr', type: 'year' },
            { name: 'Monat', type: 'month' },
            { name: 'Kalenderwoche', type: 'week' },
            { name: 'Tag', type: 'day' }
        ];

        $scope.type = 'year';

        $scope.machines = [];
        $scope.values = {};

        $scope.years = $scope.range(2015, moment().year());
        $scope.months = $scope.range(1, 12);
        $scope.days = $scope.range(1, 31);
        $scope.weeks = $scope.range(1, 53);

        $scope.add = function(machine_id) {
            $scope.values[machine_id].push({
                field: null,
                year: moment().year(),
                month: moment().month() + 1,
                day: moment().date(),
                week: moment().isoWeek()
            });
        }

        $scope.copy = function(machine_id, value) {
            $scope.values[machine_id].push({
                field: value.field,
                year: value.year,
                month: value.month,
                day: value.day,
                week: value.week
            });
        }

        $scope.remove = function(machine_id, value) {
            var index = $scope.values[machine_id].indexOf(value);

            if (index >= 0) {
                $scope.values[machine_id].splice(index, 1);
            }
        }

        $scope.init = function(callback) {
            $http.get('machines').then(function(response) {
                $scope.machines = response.data;

                response.data.forEach(function(machine) {
                    $scope.values[machine._id] = [];
                });

                (callback || ng.noop)();
            });
        }

        var ticks = [];

        $scope.options = {
            chart: {
                type: 'lineChart',
                height: 450,
                margin: { top: 20, right: 20, bottom: 40, left: 55 },
                useInteractiveGuideline: true,
                x: function(d) { return d.x; },
                y: function(d) { return d.y; },
                valueFormat: function(d) {
                    return d3.format('.02f')(d);
                },
                xAxis: {
                    tickValues: [],
                    tickFormat: function(d) {
                        return $scope.options.chart.xAxis.tickValues[d];
                    },
                    axisLabel: 'Datum',
                    axisLabelDistance: -10
                }
            }
        };

        $scope.data = [];

        $scope.update = function() {
            $http.post('data', { type: $scope.type, values: $scope.values }).then(function(response) {
                $scope.options.chart.xAxis.tickValues = response.data.ticks;
                $scope.data = response.data.charts;
            });
        }

        $scope.init(function() {
            $scope.copy('56fd5d6b8417121c907d8d15', {
                field: 'BHKW1Gvs',
                year: 2015,
                month: 10,
                day: 26,
                week: 42
            });
        });

    }]);

    app.controller('ReportController', ['$scope', '$http', '$timeout', function($scope, $http, $timeout) {
        $scope.rows = [];
        $scope.machine = '';
        $scope.machines = [];

        var tick = null;

        $scope.$watch('rows', function(newValue, oldValue) {
            if (tick !== null)
            {
                $timeout.cancel(tick)
                tick = null;
            }

            tick = $timeout(function() {
                $scope.save(function() {
                    tick = null;
                });
            }, 500);
        }, true);

        $scope.save = function(callback) {
            if ($scope.machine.length > 0) {
                $http.post('/report/rows/' + $scope.machine, { rows: $scope.rows }).then((callback || ng.noop));
            }
        }

        $scope.addRow = function() {
            $scope.rows.push({
                title: '',
                position: 0,
                field_name: '',
                row_type: 'head'
            });

            $scope.save();
        }

        $scope.$watch('machine', function(newValue, oldValue) {
            if (newValue.length > 0) {
                update(newValue);
            } else {
                $scope.rows = [];
            }
        });

        function update(machineId) {
            $http.get('/report/rows/' + machineId).then(function(response) {
                $scope.rows = response.data.rows;
            });
        }

        $http.get('/machines').then(function(response) {
            $scope.machines = response.data;
        });

        $scope.$watch('')
    }]);
})(angular);
